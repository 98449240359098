<template>
  <div>
    <PageTitle title="GallRom" />
    <b-container fluid="xxl">
      <b-row>
        <b-col md="8" cols="12">
          <p><b><i>Documents et analyses de la Galloromania médiévale</i></b><br><br>
            projet dirigé par Martin Glessgen<br />
            réalisé en collaboration avec Hélène Carles, Marguerite Dallas, Cristina Dusio, Frédéric Duval, Caterina
            Menichetti, Frankwalt Möhren, Seraina Montigel, Marco Robecchi, Thomas Städtler et Paul Videsott (2024)
          </p>
          <p>La base de données des <i>Documents et analyses de la Galloromania médiévale</i> (GallRom) réunit quatre
            des <router-link :to="{ name: 'GallRomBibl' }"><i>Répertoires bibliographiques de textes galloromans
                médiévaux</i> (GallRomBibl)</router-link>, la collection centenaire
            des <router-link :to="{ name: 'DocLing' }">Documents linguistiques galloromans (DocLing)</router-link>, le
            <router-link :to="{ name: 'LEGaMe' }">Lexique étymologique de la Galloromania médiévale (LEGaMe)</router-link>
            ainsi qu'un volet consacré aux analyses scriptologiques des DocLing (Scripta), en cours de construction.
            GallRom poursuit l'idée d'une gestion commune de ces différentes ressources permettant d'appréhender sur une
            base nouvelle la production textuelle et le lexique de la Galloromania médiévale.
          </p>
          <p>La GallRomBibl comporte les bibliographies de référence du <i>Dictionnaire étymologique de l'ancien
              français</i> (DEAF), du <i>Dictionnaire onomasiologique de l'ancien gascon</i> (DAG) ainsi qu'une fusion de
            la bibliographie du <i>Dictionnaire onomasiologique de l'ancien occitan</i> (DAO) et du <i>Répertoire critique
              des manuscrits occitans médiévaux</i> (RepCrit).</p>
          <p>La présente version des DocLing remplace la troisième édition du site antérieur (www.rose.uzh/docling, 2016),
            en élargissant fortement autant les corpus textuels que les fonctionnalités d'interrogation.</p>
          <p>Le LEGaMe intègre les données restructurées du DEAF ainsi que les formes lemmatisées des DocLing dans le
            cadre du <i>Trésor étymologique d'ancien français</i> (TEAF). Par ailleurs, il donne lieu à la publication
            électronique du <i>Dictionnaire étymologique d'ancien gascon</i> (DEAG). S'ajoutera par la suite le
            <i>Dictionnaire étymologique d'ancien occitan</i> (DEAO), en préparation.
          </p>
          <p>Les différents sous-ensembles de GallRom sont gérés par un seul système de base de données (Phoenix-3) qui
            comporte deux volets, l'un consacré aux sources textuelles, l'autre aux données lexicales. La structure
            relationnelle de la base met en lien les textes et manuscrits avec les variétés linguistiques et les
            occurrences lexicales en question. Ainsi, toute donnée langagière peut être placée dans le diasystème
            diachronique : par la datation des textes et des manuscrits, par leur localisation et – idéalement – leur
            rattachement à un scriptorium défini.</p>
          <p>La base GallRom est évolutive et sera amenée à être complétée, élargie et corrigée. Les bibliographies et les
            corpus textuels en ligne des DocLing représentent dès à présent des ressources fiables, même si elles sont
            perfectibles. Le TEAF et le DEAG, dans LEGaMe, se trouvent toutefois à l'état de chantier: les matériaux
            réunis sont dès à présent d'une grande utilité mais l'état d'avancement de leur structuration est très inégal
            d'une entrée lexicologique à une autre et il convient de les utiliser de manière critique.
          </p>
          <a href="/pdfs/further-information.pdf" class="btn btn-secondary btn-legam mt-4">
            <svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="file pdf fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-file-pdf-fill mx-auto b-icon bi" data-v-41be6633=""><g data-v-41be6633=""><path d="M5.523 10.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.035 21.035 0 0 0 .5-1.05 11.96 11.96 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.888 3.888 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 4.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z"></path><path fill-rule="evenodd" d="M4 0h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm.165 11.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.64 11.64 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.707 19.707 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"></path></g></svg>
            {{ $t('FurtherInformation') }}
          </a>
        </b-col>
        <b-col md="4" cols="12">
          <div class="action-buttons">
            <router-link :to="{ name: 'GallRomBibl' }">
              <div class="action-box">
                <p class="title text-bold">GallRomBibl</p>
                <p class="subtitle">Répertoires bibliographiques de textes galloromans médiévaux</p>
                Version électronique<br>
                dirigée par Martin Glessgen<br>
                en partenariat avec Caterina Menichetti et Frankwalt Möhren<br>
                Version préparatoire (2024)
                <svg xmlns="http://www.w3.org/2000/svg" height="30" width="30" viewBox="0 0 512 512" class="arrow">
                  <path
                    d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM281 385c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l71-71L136 280c-13.3 0-24-10.7-24-24s10.7-24 24-24l182.1 0-71-71c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L393 239c9.4 9.4 9.4 24.6 0 33.9L281 385z" />
                </svg>
              </div>
            </router-link>
            <router-link :to="{ name: 'DocLing' }">
              <div class="action-box">
                <p class="title text-bold">DocLing</p>
                <p class="subtitle">Documents linguistiques galloromans</p>
                Édition électronique<br />
                dirigée par Martin Glessgen<br />
                en partenariat avec Frédéric Duval, Paul Videsott et Hélène
                Carles<br />
                Quatrième édition (2024)
                <svg xmlns="http://www.w3.org/2000/svg" height="30" width="30" viewBox="0 0 512 512" class="arrow">
                  <path
                    d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM281 385c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l71-71L136 280c-13.3 0-24-10.7-24-24s10.7-24 24-24l182.1 0-71-71c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L393 239c9.4 9.4 9.4 24.6 0 33.9L281 385z" />
                </svg>
              </div>
            </router-link>
            <router-link :to="{ name: 'LEGaMe' }">
              <div class="action-box">
                <p class="title text-bold">LEGaMe</p>
                <p class="subtitle">Lexique étymologique de la Galloromania médiévale</p>
                Base de données lexicographique<br />
                dirigée par Martin Glessgen<br />
                en partenariat avec Hélène Carles, Marguerite Dallas, Cristina
                Dusio, Seraina Montigel et Thomas Städtler<br />
                Version préparatoire (2024)
                <svg xmlns="http://www.w3.org/2000/svg" height="30" width="30" viewBox="0 0 512 512" class="arrow">
                  <path
                    d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM281 385c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l71-71L136 280c-13.3 0-24-10.7-24-24s10.7-24 24-24l182.1 0-71-71c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L393 239c9.4 9.4 9.4 24.6 0 33.9L281 385z" />
                </svg>
              </div>
            </router-link>
            <router-link :to="{ name: 'Scripta' }">
              <div class="action-box">
                <p class="title text-bold">Scripta</p>
                <p class="subtitle">Analyses scriptologiques des DocLing</p>
                dirigée par Martin Glessgen et Paul Videsott<br />
                en collaboration avec Cristina Dusio, Jessica Meierhofer et Marco
                Robecchi
                <svg xmlns="http://www.w3.org/2000/svg" height="30" width="30" viewBox="0 0 512 512" class="arrow">
                  <path
                    d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM281 385c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l71-71L136 280c-13.3 0-24-10.7-24-24s10.7-24 24-24l182.1 0-71-71c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L393 239c9.4 9.4 9.4 24.6 0 33.9L281 385z" />
                </svg>
              </div>
            </router-link>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style scoped>
.title {
  font-size: 20px;
  margin-bottom: 10px;
}

.subtitle {
  font-weight: bold;
  font-style: italic;
}

.action-box {
  position: relative;
  font-size: 14px;
  color: #fff;
  padding: 10px 15px;
  border-radius: 4px;
  /* border: 1px solid #8c17153b; */
  background-color: #8c1715;
  transition: all 0.3s;
  margin-bottom: 10px;
}

.action-box svg.arrow {
  opacity: 0;
  position: absolute;
  top: 10px;
  right: 10px;
  fill: #fff;
  transition: all 0.3s;
}

.action-box:hover {
  background-color: #8c1715b7;
}

.action-box:hover svg.arrow {
  opacity: 1;
}

.action-buttons a {
  text-decoration: none;
}
</style>

<script>
import PageTitle from '@/components/PageTitle.vue'

export default {
  name: 'GallRomDetails',
  data() {
    return {

    }
  },
  components: {
    PageTitle,
  },
  computed: {
  },
  mounted() {
  },
  watch: {
  },
  methods: {
  }
}
</script>
